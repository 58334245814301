import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import SolarImg from '../../../assets/images/solar-gates-img.jpg'

class SolarGates extends React.Component {
	render() {
		const siteTitle =
			'Dallas Solar Gates - Fort Worth Solar Gates - Arlington Solar Gates'
		const siteDescription =
			'Get a custom solar gate in Dallas, Arlington, and Fort Worth, TX. Learn the benefits of solar gate installation. Call us today for our gate services!'

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />

				<div className="container-fluid text-center headline gates-headline">
					<div className="container">
						<h1 className="text-white m-0">Solar Gates in Arlington, TX</h1>
						<hr />
					</div>
				</div>
				<div className="container d-padding-t d-padding-b access-sys">
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-12">
							<p>
								Looking for an automatic gate with little energy use? A solar
								gate might be exactly what you are looking for! Call J & J Gates
								and Design at (817) 466-2794 to learn more about our solar gate
								installation in Dallas and Fort Worth, TX.
							</p>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-8">
							<h4>Benefits of Solar Powered Gates</h4>
							<p>
								Solar gates, or gates powered by solar gate openers, do exactly
								as you expect. Rather than using electricity and a hard-wired
								power line, they use the sun’s light to open your{' '}
								<Link to="/gates/">gate</Link>. You’ll save money on electricity
								since access to sunlight is completely free. By using a{' '}
								<strong>solar powered gate opener</strong>, you also reduce the
								impact you make on the environment. The costs for solar gates
								come down more every year, so they’re not as expensive as you
								might fear. All you need is about 8 hours of unobstructed light
								each day!
							</p>
							<p>
								Extremely popular in more rural parts of Texas, solar powered
								gates are reliable and efficient. However, an overall trend is
								seeing even homes and businesses in cities opt for solar power.
								In the Dallas-Fort Worth area, there is frequent sun so you do
								not have to worry about replacing a battery often. If your gate
								will be located a significant distance from your home or
								business, solar gates can also provide a hassle-free system
								without the need for electricity. Just about anyone can benefit
								from a solar gate system, as long as you do not have trees or
								tall buildings obstructing the sunlight.
							</p>
						</div>
						<div className="col-md-4 mt-5">
							<LazyLoadImage
								className="img-fluid mt-5"
								src={SolarImg}
								alt="Solar Gate"
							/>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-12">
							<p>
								No matter what kind of gate system you need, solar gates are
								available in all styles, colors, and more. You can count on J &
								J Gates Service and Design for expert solar gate installation in
								Dallas and Fort Worth, TX.{' '}
								<strong>
									<em>
										Call us today at <a href="tel:8174662794">(817)-466-2794</a>{' '}
										to learn more about our options and if a solar gate system
										would be compatible with your property
									</em>
								</strong>
								.
							</p>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default SolarGates

export const SolarGatesPageQuery = graphql`
	query SolarGatesPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
